body {
	padding-top:87px;
    &.basic {
        border-top:2px solid #d32228;
    }
}

.main {
	margin-left:250px;

	@media screen and (max-width : 768px)
	{
		margin-left:0;
	}
}

.nav.nav-tabs {
	padding-top:30px;
	li
	{
		padding-right: 1px;
		a
		{
			border-radius:0px;
			font-weight: 900;
			background-color:$gray;
			color:$sideBarText;
			&:hover, &:focus
			{
				font-weight: 900;
				background-color:$gray;
                color:black;
			}

		}
		&.active
		{
			a
			{

				background-color:$blue;
				color: white;
				font-weight: 900;

			}
		}

	}
}

.bg-gray {
	background-color:$gray;
}

// override input style
.form-group {
	&.has-error {
		.form-control {
			border:2px solid $red;
			box-shadow:none;
			&:focus, &:active {
				border:$red 2px solid;
			}
		}
	}
}
.form-control {
	border: 1px $lightBlue solid;
	box-shadow : none;
	border-radius:0;
	height:40px;
	color:$darkerGray;
	@include transition(border-width ease-in .15s);

	&:focus,&:active {
		border-width:2px;
		box-shadow:none;
	}
}

// override button style
.btn {
	border-radius: 0;
	border: 0;
	font-weight:bold;
}

// panel override
.panel {
	box-shadow:none;
	border-radius:0;
	.panel-heading {
		border-radius: 0 ;
		background-color: $gray;
	}

	&.bordered {
		border-width:1px;
		border-style:solid;
		border-color:$gray;
	}
}

// table override
.table {
    > thead > tr > th {
        border-bottom: 0 none;
    }
	&.v-middle {
		tr td {
			vertical-align: middle;
		}
	}
    tr.colored {
        background-color:#f9f9f9;
    }
}

// five columns grid
.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}
.col-xs-15 {
    width: 20%;
    float: left;
}
@media (min-width: 768px) {
.col-sm-15 {
        width: 20%;
        float: left;
    }
}
@media (min-width: 992px) {
    .col-md-15 {
        width: 20%;
        float: left;
    }
}
@media (min-width: 1200px) {
    .col-lg-15 {
        width: 20%;
        float: left;
    }
}
.spinner {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99999999;
    background: #FFF;
    opacity: .9;
    > i {
    	position: absolute;
	    top: 45%;
	    left: 45%;
	    font-size: 6em;
	    color: #d32228;
    }
}

.filters {
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    padding-top:0!important;
    margin: 15px 0 9px 0;
    .periods {
        > span {
            font-weight: bold;
        }
    }
    .finder {
        cursor: pointer;
        display: inline;
        .text {
            border-right: 1px solid #777;
            margin-right: 8px;
            padding: 12px;
        }
    }
}

// override dropdown
.dropdown-menu {
    @include box-shadow(1px 2px 0px rgba(0,0,0,.175));
    top:105%;
    &:before, &:after {
        position: absolute;
        display: inline-block;
        border-bottom-color: rgba(0, 0, 0, 0.2);
        content: '';

    }
    &:before {
        top: -7px;
        right:9px;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        border-bottom: 7px solid #ccc;
    }
    &:after {
        right:10px;
        top: -6px;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #fff;
        border-left: 6px solid transparent;
    }
}
//well override
// Base class
.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: $well-bg;
  border: 1px solid $well-border;
  border-radius: 0;
  @include box-shadow(inset 0 0px 0px rgba(0,0,0,.05));
  blockquote {
    border-color: #ddd;
    border-color: rgba(0,0,0,.15);
  }

  &.no-border {
    border:0 none;
  }
}

// Sizes
.well-lg {
  padding: 24px;
  border-radius: 0;
}
.well-sm {
  padding: 9px;
  border-radius: 0;
}
.page-header {
    margin : 20px 0;
    border: 0 none;
    + .nav {
        padding-top:0;
    }
}

.select2-container {
    
    .select2-selection--single {
        height: 40px!important;
        border-color: $lightBlue;
        color:#393d40;
        line-height: 1.8;
        .select2-selection__rendered {
            color: #393d40;    
        }
    }
    
    .select2-dropdown {
        border-radius:0;
    }

    .select2-search--dropdown {
        .select2-search__field {
            border-color: $lightBlue;
            color:#393d40;
        }
    }
}

//modals override
// Actual modal
.modal-content {
  border-radius: 0;
  @include box-shadow(1px 2px 0px rgba(0,0,0,.175));
}
@media (min-width: $screen-sm-min) {
  // Automatically set modal's width for larger viewports
  .modal-content {
    @include box-shadow(1px 2px 0px rgba(0,0,0,.175));
  }
}

//override input group btn
.input-group-btn .btn{
    min-height: 40px;
}

//spazio superiore per dataTable
.dataTables_wrapper {
    padding-top: 8px;
}