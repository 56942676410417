.meter {
    position: relative;
    height: 24px;
    width: 100%;
    display: block;
    background-color: #aeea93;
    overflow: hidden;

    /* &:after {
      content:attr(data-value);
      display: block;
      top: -24px;
      right: 4px;
      position: relative;
      font-size: 16px;
    } */

    > span {
      background-color: #6dc844;
      width: 100%;
      height: 24px;
      display: block;
      -webkit-transition: all .3s ease-in;
      -moz-transition: all .3s ease-in;
      -ms-transition: all .3s ease-in;
      -o-transition: all .3s ease-in;
      transition: all .3s ease-in;
    }
}