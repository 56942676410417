@font-face {
    font-family: 'smtp-plus-icon';
    src:    url('/fonts/icomoon.eot?cb6o4x');
    src:    url('/fonts/icomoon.eot?cb6o4x#iefix') format('embedded-opentype'),
        url('/fonts/icomoon.ttf?cb6o4x') format('truetype'),
        url('/fonts/icomoon.woff?cb6o4x') format('woff'),
        url('/fonts/icomoon.svg?cb6o4x#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class^="smtp-icon-"], [class*=" smtp-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'smtp-plus-icon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'smtp-plus-loading';
    src:    url('/fonts/smtp-plus-loading.eot?wwo6um');
    src:    url('/fonts/smtp-plus-loading.eot?wwo6um#iefix') format('embedded-opentype'),
        url('/fonts/smtp-plus-loading.ttf?wwo6um') format('truetype'),
        url('/fonts/smtp-plus-loading.woff?wwo6um') format('woff'),
        url('/fonts/smtp-plus-loading.svg?wwo6um#smtp-plus-loading') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="smtp-loading-"], [class*=" smtp-loading-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'smtp-plus-loading' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.smtp-loading-icon:before {
    content: "\e900";
}

.smtp-icon-emailHistory:before {
    content: "\e901";
}
.smtp-icon-hardbounce:before {
    content: "\e902";
}
.smtp-icon-nav-logout:before {
    content: "\e903";
}
.smtp-icon-nav-pagamenti:before {
    content: "\e904";
}
.smtp-icon-nav-settings .path1:before {
    content: "\e905";
    color: rgb(135, 135, 135);
}
.smtp-icon-nav-settings .path2:before {
    content: "\e906";
    margin-left: -1em;
    color: rgb(238, 237, 237);
}
.smtp-icon-nav-support:before {
    content: "\e907";
}
.smtp-icon-price:before {
    content: "\e908";
}
.smtp-icon-settings:before {
    content: "\e909";
}
.smtp-icon-statistics:before {
    content: "\e90a";
}
.smtp-icon-tag:before {
    content: "\e90b";
}
.smtp-icon-unsubscribers:before {
    content: "\e90c";
}
.smtp-icon-users:before {
    content: "\e90d";
}
