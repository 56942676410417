
.main 
{
	.tab-content 
	{
		#reportrange
		{
			padding-top: 10px;

		}
		.stats
		{

			.spinner
			{

			}

			.totalSats
			{
		
				#allStats
				{
					cursor:pointer;
					.elementVal
					{
						font-size: 30px;
						
					}
				}
			}
		}
							
	}
}

svg {
    width: 100% !important;
    padding-top: 10px;
}

#userstats {
    min-height: 400px;
}

#donutPlaceholder {
    font-size: 30px;
    text-align: center;
    padding-top: 120px; 
}

.morris-hover {
	position: absolute;
	z-index: 1000;
	&.morris-default-style {
		border-radius: 10px;
		padding: 6px;
		color: #666;
		background: rgba(255, 255, 255, 0.8);
		border: solid 2px rgba(230, 230, 230, 0.8);
		font-family: sans-serif;
		font-size: 12px;
		text-align: center;
	}
	&.morris-default-style .morris-hover-row-label {
		font-weight: bold;
		margin: 0.25em 0;
	}
	&.morris-default-style .morris-hover-point {
		white-space: nowrap;
		margin: 0.1em 0;
	}
}