.multi-filters {
    border-bottom : 1px solid #ddd;
    .filters {
        margin-top: 14px;
        border-bottom : 0px;
    }

    .select-filters {
        margin-left : 10px;
    }
}