.prices {
    .product {
        margin-bottom: 35px;
        position: relative;
        outline : 0px solid $green;
        &:hover, &.active {
            cursor:pointer;
            border:0;
            outline : 2px solid $green;
            @include transition(outline .15s);
            .panel-body .checked {
                opacity: 1;
            }
        }
        .panel-heading {
            padding:5px;
            .name {
                margin : 0;
                font-weight: bold;
            }
        }
        .panel-body {
            padding: 40px 15px 10px;
            .price {
                font-size: 40px;
                color:$blue;
                font-weight: bold;
            }
            .checked {
                position: relative;
                display: block;
                margin:0 auto;
                opacity: 0;
                bottom:-25px;
                width: 30px;
                height: 30px;
                background: $green;
                -moz-border-radius: 70px;
                -webkit-border-radius: 70px;
                border-radius: 70px;
                @include transition(opacity .15s);
                > i {
                    color:white;
                    font-size: 26px;
                    padding: 2px;
                }
            }
        }
    }
}