$lighterBlue : #ddf0f7;
$lightBlue : #bae9f7;
$blue : #54c6f2;

$darkerGray : darken(#b8bdc0, 50%);
$darkGray : #b8bdc0;
$gray : #dae1e4;

$red : #d32228;
$green : #6dc844;

$break-small: 320px;
$break-large: 1200px;

$brand-primary:         darken(#54c6f2, 6.5%) !default; // #337ab7
$brand-success:         $green !default;
$brand-info:            #5bc0de !default;
$brand-warning:         #f0ad4e !default;
$brand-danger:          #d32228 !default;
$state-danger-text:     $brand-danger;

$table-cell-padding :   14px;

$font-family-sans-serif:  "Lato", Helvetica, Arial, sans-serif !default;
$font-family-base:        $font-family-sans-serif !default;


$spams : #0099ff;
$spamServer: #0000ff;
$mailbox: #ff0000;
$domain: #ccff33;
$mailboxFull: #003300;

$sideBarText:#878787;

$tooltip-color : white;
$tooltip-bg : $darkerGray;

$border-radius-base : 0;
$well-bg : $lighterBlue;

$icon-font-path: "/fonts/bootstrap/"; //bootstrap
$fa-font-path:   "/fonts"; //fontawesome

$form-control-default-box-shadow : none;
$dropdown-box-shadow:0 none;