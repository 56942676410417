.sidebar {
	position: fixed;
	top: 87px;
	bottom: 0;
	left: 0;
	z-index: 1000;
	display: block;
	padding: 0;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: $lighterBlue;
	border-right: 1px solid $gray;
	min-width:250px;
	width:250px;
	@include transition(width .10s, min-width .10s);

	@media screen and (max-width : 768px)
	{
		width: 0px;
		min-width: 0px;
	}

	.navbar-collapse
	{
		padding: 0px;
	}
	.forSidebar
	{

		.nav-sidebar {
			li {

				border-bottom:1px solid $gray;

				.list-group-submenu
				{
					background-color:white !important;
					a {
						display: block;

						&.active, &:hover, &:focus {
							background-color: $gray;
							text-decoration: none;
							.icon {
								color:white;
								background-color:$darkGray;
							}
						}
						.icon {
							color:$darkGray;
							background-color:white;
						}

						.element-name {
							padding: 8px;
							display: inline-block;
							font-weight:900
						}
					}
				}
				a {
					color:black;
					padding:0;
					position: relative;
					display: block;
					&.active {
						background-color: $lightBlue;
						.icon {
							background-color:$blue;
							color:white;
							z-index: 2;
						}
					}
					&:hover, &:focus {
						@extend .active
					}
					.icon {
						color:$blue;
						display: inline-block;
						padding: 8px 15px;
						font-size: 20px;
						border-right:1px solid $gray;
					}

					.element-name {
						padding: 10px;
						display: inline-block;
						font-weight:900;
						color:$sideBarText;

					}

					.icon-caret {
						position: absolute;
						right: 5px;
						top:10px;
					}
				}
				
			}
		}
	}

}