#register-page
{
	max-width: 350px;
	margin: 0 auto;

	&:after {
		content : '';
		background : url('/img/img_page_login.png') no-repeat;
		background-position: bottom center;
		background-size: contain;
		position: absolute;
	    width: 100%;
	    height: 365px;
	    right: 0;
	    bottom: 0;
	    z-index:-1;
	}

	.logo-img {
		margin-bottom:20px;
	}

	form {
		padding:30px 15px;
		background-color: $lighterBlue;

		.reset {
			font-size: 12px;
		}
	}
}