.bounces
{
	#errorGraph
	{
		.deliveryErrors
		{
			.bounceFooter
			{
				.bounceFooterElement
				{
					text-align: center;
					vertical-align: middle;
					line-height: 48px;

				}
				p
				{    margin-right: 40px;
				}
				#footerBounceSpam
				{
					height: 50px;
					width: 50px;
					text-align: center;
					background-color: $spams;
					vertical-align: middle;
				}
				#footerBounceSpamServer
				{
					height: 50px;
					width: 50px;
					text-align: center;
					background-color: $spamServer;
					vertical-align: middle;
				}
				#footerBounceMailBox
				{
					height: 50px;
					width: 50px;
					text-align: center;
					background-color: $mailbox;
					vertical-align: middle;
				}
				#footerBounceDomain
				{
					height: 50px;
					width: 50px;
					text-align: center;
					background-color: $domain;
					vertical-align: middle;
				}
				#footerBounceFull
				{
					height: 50px;
					width: 50px;
					text-align: center;
					background-color: $mailboxFull;
					vertical-align: middle;
				}
			}
			.bounceDonut
			{

			}
		}
	}
	.bouncesTable {
		tr.totals {
			font-size: 22px;
			td {
				border: 0;
				padding: 25px 8px;
			}
		}
		td {
			vertical-align: middle;
			border: 1px solid #ddd;
		}
	}
	.elementVal {
		font-size:30px;
		&.nomailbox {
			color: #3D5CA4;
		}
		&.nodomain {
			color: #72B438;
		}
		&.mailboxfull{
			color: #D51625;
		}
		&.spamrecipient{
			color: #EF8A58;
		}
		&.spamdomain{
			color: #97599D;
		}

	}
	.elementText {
	    font-size: 22px;
	    color:#b1b5ba;
	    display:block;
	}
}

