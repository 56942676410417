.navbar {
	background-color : $lighterBlue;
	border-color : $gray;
	border-top:2px solid #d32228;
	.navbar-brand {
		height: 100%;
		background-color:white;
		width: 250px;
		min-width: 250px;
		img {
			margin:0 auto;
		}

		@media screen and (max-width : $screen-md) {
			display: block;
			margin:0 auto;
			float:none;
		}
	}

	.navbar-nav {
		min-height: 87px;
		padding-right: 10px;
		> li:not(:last-child) {
			border-right:1px solid #d2d2d2;
			> a {
				padding : 25px 20px
			}
		}
		li.dropdown > a {
			padding-top:18px;
			padding-bottom:18px;
			color:black;
			font-size: 16px;
			&:hover, &:focus {
				background-color:transparent;
			}
			.caret {
				top:39px;
				right: -5px;
				color:black;
			}
		}

		li.icon-element > a {
			&:hover, &:focus {
				background-color:transparent;
			}
		}

		@media screen and (max-width : $screen-xs) {
			padding-right: 0;
			min-height: auto;
			text-align: center;
			> li {
				display: inline;
				border:0 !important;

				> a {
					display: inline;
					padding:0;
				}
			}
		}

		.avatar-pic {
			padding:12px 10px;
			figure {
				overflow: hidden;
			    margin: 0 auto;
			    width: 50px;
			    height: 50px;
			    border: 1px solid white;
			    display: inline-block;
			    vertical-align: middle;
			    -moz-border-radius: 50%;
			    -webkit-border-radius: 50%;
			    -ms-border-radius: 50%;
			    border-radius: 50%;

				> span {
					background-color:#54c6f2;
					font-size: 32px;
    				padding: 14px 0;
				    text-align: center;
					display: block;
				    color: #fff;
				    text-transform: uppercase;
				}
			}
		}
	}
}

.navbar-toggle {
	position: absolute;
    padding: 15px 15px;
    border: 4px solid transparent;
    border-radius: 0;
    top: 0;
    right: 0;
    float:none;
    margin:0;
    background-color:$gray;
    &.sidebar-toggle {
    	top: 0;
    	left: 0;
    }
    .icon-bar {
    	display: block;
	    width: 40px;
	    margin: 10px 0px;
	    height: 3px;
	    border-radius: 0px;
	    background: white;
    }
}
