.newuser
{
		button 
		{
			background-color: $blue;
			border-radius: 0px;
			border :none;
			font-weight: 900;
			display: block;
			width: 80%;
			float: right;
			height: 40px;
			margin-bottom: 10px;
			color:white;
		}
}


.listusers 
{
	border: none;
	table 
	{
		thead  
		{
			background-color: $gray;
			tr 
			{
				th 
				{
					border-bottom:none;
				}
				td
				 {
				    a 
				    {
				    	background-color: $blue;
				    }
				}
						
				
			}
			
		}
	}
}